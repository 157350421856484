@font-face {
  font-family: "Barcode";
  src: url(./Barcode.ttf);
}

@font-face {
  font-family: "Handwritten";
  src: url(./Handwritten.ttf);
}

@font-face {
  font-family: "Printer";
  src: url(./Printer.ttf);
}
